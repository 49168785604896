import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/ui/css/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './port/router'
import { KeycloakAuthProvider } from '@/port/provider/KeyCloakAuthProvider'
import themes from 'devextreme/ui/themes'
import { ApiWsClient } from '@/port/provider/ApiWsClient'
import { ApiClient } from '@/port/provider/ApiClient'
import { useSession } from '@/domain/repository/Session'
import {sentry} from "@/system/operations/sentry";


////////////////
// Load Theme //
////////////////
export const DEFAULT_THEME = 'material.blue.light.compact'
export const STORAGE_THEME_KEY = 'dx-theme'
const storedThemePreference = window.localStorage.getItem(STORAGE_THEME_KEY)
try{
  // themes.current(storedThemePreference || DEFAULT_THEME);
} catch (e) {
  console.warn(`Invalid theme ('${storedThemePreference}') set in localstorage "dx-theme". Using default: ${DEFAULT_THEME}`);
  window.localStorage.removeItem(STORAGE_THEME_KEY)
}

const app = createApp(App)
sentry.init(app)
app.use(createPinia())
app.use(router)

console.debug(`application-version: ${(window as any).applicationVersion}`)
export const ApiWsClientInstance = KeycloakAuthProvider.authenticate().then(() => {
  ApiClient.getUser().then(user => {
    const sessionStore = useSession()
    sessionStore.setUser(user)
  })
  app.mount('#app')
}).then(() =>{
  return ApiWsClient()
})

