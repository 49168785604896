import * as Sentry from "@sentry/vue";
import {cfg, Config} from "@/lib/utils/config";

export const sentry = {
  init(app:any){
    if(cfg(Config.SENTRY_ENABLED)){
      console.info("Sentry Enabled")
      Sentry.init({
        app,
        dsn: "https://9e6955aefd72ec3424f6b50fbc734082@o4507731005407232.ingest.us.sentry.io/4507731007176704",
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost:4000", "slingshot.qa.nnncapital.cloud"],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    } else {
      console.info("Sentry Disabled")
    }
  }
}
