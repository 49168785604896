import Dexie, {type EntityTable, type IndexableType} from 'dexie';
import type { Chain } from '@/domain/entity/Chain';
import type { ChainLocation } from '@/domain/entity/ChainLocation';
import type { Property } from '@/domain/entity/Property';
import type { ImportListing, Listing, ListingView } from '@/domain/entity/Listing';
import type { Contact } from '@/domain/entity/Contact';
import type { Company } from '@/domain/entity/Company';
import type { Address } from '@/domain/entity/Address';
import type { User } from '@/domain/entity/User';

export type schema = {
  properties: EntityTable<Property, 'id'>; // primary key "id" (for the typings only)
  chains: EntityTable<Chain, 'id'>;
  chainLocations: EntityTable<ChainLocation, 'id'>;
  externalListings: EntityTable<ImportListing, 'id'>;
  listings: EntityTable<Listing, 'id'>;
  listingsWithTimeSeries: EntityTable<ListingView, 'id'>;
  users: EntityTable<User, 'id'>;
  contacts: EntityTable<Contact, 'id'>;
  companies: EntityTable<Company, 'id'>;
  addresses: EntityTable<Address, 'id'>;

  application: EntityTable<Record<string, any>, 'id'>;
};

const db = new Dexie("slingshot") as Dexie & schema;

// Define database schema
db.version(2).stores({
  chains: "&id, chainId, name",
  chainLocations: "&id, chainId, chainName, latitude, longitude, locationPoint",
  externalListings: "&id, geo.latitude, geo.longitude",
  listings: "&id, geo.latitude, geo.longitude",
  listingsWithTimeSeries: "&id, geo.latitude, geo.longitude",
  properties: "&id, latitude, longitude, latLng",
  users: "&id",
  contacts: "&id, companyKey, addressKey, mailingAddressKey, workAddressKey, homeAddressKey, otherAddressKey",
  companies: "&id, addressKey",
  addresses: "&id, latitude, longitude, contactKey",
  application: "&id"
});

// Clear all tables
const clearAllTables = async () => {
  const tableNames = db.tables.map(table => table.name);
  for (const tableName of tableNames) {
    await db.table(tableName).clear();
  }
};

// Workers

// const bulkWriteToWorker = (tableName: keyof typeof db, data: any[]) => {
//   return _bulkWriteToWorker(new BulkPutDbWorker() as BulkPutWorker, tableName, data);
// };



export {
  db,
  clearAllTables
};
