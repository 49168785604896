export enum AuthErrorType {
  NOT_AUTHENTICATED = 'Not Authenticated',
  UNKNOWN = 'Unknown'
}

export class AuthenticationError extends Error {
  private type: AuthErrorType

  constructor(message: string, type: AuthErrorType) {
    super(`${message} - ${type}`)
    this.type = type
    Object.setPrototypeOf(this, AuthenticationError.prototype)
  }

  getType() {
    return this.type
  }
}

export type AuthProvider = {
  authenticate: () => Promise<boolean | AuthenticationError>
  isAuthenticated: () => boolean
  logout: () => Promise<void>
  token: () => string
  tokenDetails: () => { [k: string]: any }
  updateToken: () => Promise<boolean | void>
  userRoles: () => string[] | undefined
  getAuthorizationHeaderValue: () => Promise<string>
}
