import {defineStore} from 'pinia'
import type {CurrentUser} from '@/domain/entity/CurrentUser'
import {reactive, ref, toRaw} from 'vue'
import type {AppState} from '@/domain/entity/AppState'
import {ListingType} from '@/domain/value/ListingType'
import {db} from '@/system/db'

export const useSession = defineStore('session',  () => {
  // init
  const userState = ref<CurrentUser>()
  const appState = reactive<AppState>({
    activeListingType: ListingType.UNKNOWN
  })

  const loadAppState = async () => {
    const cachedState = await db.application.get('appState') as AppState
    if(cachedState){
      appState.activeListingType = cachedState.activeListingType || ListingType.UNKNOWN
    } else {
      appState.activeListingType = ListingType.UNKNOWN
    }
  }

  // actions
  const setUser = (user: CurrentUser) => {
    userState.value = user
  }
  const setActiveListingType = async (type:ListingType) => {
    appState.activeListingType = type
    const appStateRecord:{id:string, [key:string]:any} = {
      id: 'appState',
      ...toRaw(appState)
    }
    return db.application.put(appStateRecord)
  }

  // load
  loadAppState().then(() => console.debug(`Loaded session.appState = ${JSON.stringify(toRaw(appState))}`))

  // return
  return {
    user: userState,
    setUser,

    app: appState,
    setActiveListingType
  }
})
