import { cfg, Config } from '@/lib/utils/config'
import { KeycloakAuthProvider } from '@/port/provider/KeyCloakAuthProvider'

export const ApiWsClient = () => {

  const protocol = cfg(Config.WEB_SOCKET_PROTOCOL)
  const tokenDetails = KeycloakAuthProvider.tokenDetails()
  console.debug(tokenDetails)
  const host = window.location.host
  const socket = new WebSocket(`${protocol}://${host}/api/bus/me`)

  socket.onopen = function(_) {
    socket.send('My name is John')
  }

  socket.onmessage = function(event) {
    console.debug(`[message] Data received from server: ${event.data}`)
  }

  socket.onclose = function(event) {
    if (event.wasClean) {
      console.debug(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`)
    } else {
      // e.g. server process killed or network down
      // event.code is usually 1006 in this case
      console.warn('[close] Connection died')
    }
  }

  socket.onerror = function(error) {
    console.error(`[error]`, error)
  }

  return  {
    send: (message: any) => {
      console.log(JSON.stringify(message))
      socket.send(JSON.stringify(message))
    }
  }

}
