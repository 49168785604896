import dayjs from 'dayjs'


export namespace DateUtil {
  export const parseString = (date:string):Date => {
    return dayjs(date).toDate()
  }
  export const formatDateTimeString = (isoTimestamp:string): string => {
    return dayjs(isoTimestamp).format('ddd, MMM D, YYYY h:mm A')
  }
  export const formatSimpleDateString = (isoTimestamp:string): string => {
    return dayjs(isoTimestamp).format('ddd, MMM D, YYYY')
  }
}
