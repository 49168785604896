import axios, {AxiosError, type InternalAxiosRequestConfig} from 'axios'
import { KeycloakAuthProvider } from '@/port/provider/KeyCloakAuthProvider'
import { cfg, Config } from '@/lib/utils/config'
import type { ListingProvider } from '@/domain/provider/ListingProvider'
import type { Listing } from '@/domain/entity/Listing'
import { type CrexiListing, CrexiListingFn } from '@/port/provider/crexi/CrexiListing'
import type { CurrentUser } from '@/domain/entity/CurrentUser'
import type { CurrentUserProvider } from '@/domain/provider/CurrentUserProvider'
import type { AutocompleteProvider } from '@/domain/provider/AutocompleteProvider'
import type { GeoProvider } from '@/domain/provider/GeoProvider'
import type { RetailerAutocompleteProvider } from '@/domain/provider/RetailerAutocompleteProvider'
import type { RetailerLocationProvider } from '@/domain/provider/RetailerLocationProvider'

const httpClient = axios.create({
  baseURL: '/api/',
  timeout: cfg(Config.HTTP_TIMEOUT_MILLIS),
  headers: {
    'Accept': 'application/json'
  }
})

httpClient.interceptors.request.use(async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  if (KeycloakAuthProvider.isAuthenticated()) {
    return KeycloakAuthProvider.updateToken().then(() => {
      config.headers.Authorization = `Bearer ${KeycloakAuthProvider.token()}`
      return config
    })
  }
  return Promise.resolve(config)
})

type ApiRestClient = {
  get: <T>(url:string) => Promise<T>
  put: <T>(url:string, data: T) => Promise<T>
  delete: <T>(url:string) => Promise<T>
}
export const ApiClient: ListingProvider &
                        CurrentUserProvider &
                        AutocompleteProvider &
                        RetailerAutocompleteProvider &
                        RetailerLocationProvider &
                        GeoProvider &
                        ApiRestClient = {

  async getExternalListings(latest?: boolean, sinceDaysAgo?:number): Promise<Listing[]> {
    console.debug(`getExternalListings(${latest}, ${sinceDaysAgo}`)
    const res = await httpClient.get(`external-listings?latest=${latest || 'false'}&sinceDaysAgo=${sinceDaysAgo || 30}`)
    console.log(`Fetched ${res.data.length} External Listings`)
    const listings = (res.data as CrexiListing[])
      .filter((l:CrexiListing) => l.locations && l.locations.length && l.locations.length > 0)
      .map((l: CrexiListing): Listing => CrexiListingFn.toDomain(l))
    console.log(`Filtered ${listings.length} External Listings`)
    return listings
  },

  async getExternalListingDetail(id:string, latest?: boolean): Promise<any> {
    const res = await httpClient.get(`external-listings/${id}?latest=${latest || 'false'}`)
    if(res.status === 204){
      return null
    } else {
      return (res.data as any)
    }
  },

  async deleteExternalListings(ids: string[]) : Promise<void> {
    const res = await httpClient.post(`/external-listings/deleted`, ids)
    return (res.data as any)
  },
  async importExternalListings(items: {id:string, itemName?:string, itemType?:string}[]) : Promise<void> {
    const res = await httpClient.post(`/external-listings/imported`, items)
    return (res.data as any)
  },
  async saveExternalListings(items: {id:string, itemName?:string, itemType?:string}[]) : Promise<void> {
    const res = await httpClient.post(`/external-listings`, items)
    return (res.data as any)
  },
  async getListings(latest?: boolean): Promise<Listing[]> {
    const res = await httpClient.get(`listings?latest=${latest || 'false'}`)
    return (res.data as Listing[])
  },

  async saveListings(listings: Listing[]): Promise<void> {
    const res = await httpClient.post(`listings`, listings)
    return (res.data as any)
  },

  async getUser(): Promise<CurrentUser> {
    const res = await httpClient.get('user')
    return (res.data as CurrentUser)
  },

  async autocomplete(input:string, bias?:{lat:number,lng:number}): Promise<any[]> {
    const pointBias = bias ? `&pointBias=${bias.lat},${bias.lng}` : ''
    const res = await httpClient.get(`addresses/autocomplete?input=${input}${pointBias}`)
    return (res.data as any[])
  },

  async retailerAutocomplete(input:string): Promise<any[]> {
    const res = await httpClient.get(`provider/chainxy/autocomplete?search=${input}`)
    return (res.data as any[])
  },

  async getChainLocationsById(id:string, latest:boolean, pageSize:number): Promise<any[]> {
    const res = await httpClient.get(`provider/chainxy/chains/${id}/locations?latest=${latest}&pageSize=${pageSize}`)
    return (res.data.results as any[])
  },

  async getPlaceGeo(placeId:string): Promise<{longitude:number, latitude: number}> {
    const res = await httpClient.get(`addresses/autocomplete/place/${placeId}/geo`)
    return (res.data as {longitude:number, latitude: number})
  },

  async get<T>(url: string): Promise<T> {
    try {
      const res = await httpClient.get(url);
      return res.data as T;
    } catch (e) {
      const error = e as AxiosError;
      console.error(`GET ${url} - ${error.response?.status} - ${error.response?.statusText}`);
      // Return a rejected promise with an appropriate message
      throw new Error(`Failed to fetch data from ${url}`);
    }
  },

  async put<T>(url: string, data: T): Promise<T> {
    try {
      const res = await httpClient.put(url, data);
      return res.data as T;
    } catch (e) {
      const error = e as AxiosError;
      console.error(`PUT ${url} - ${error.response?.status} - ${error.response?.statusText}`);
      // Return a rejected promise with an appropriate message
      throw new Error(`Failed to fetch data from ${url}`);
    }
  },

  async delete<T>(url: string): Promise<T> {
    try {
      const res = await httpClient.delete(url);
      return res.data as T;
    } catch (e) {
      const error = e as AxiosError;
      console.error(`GET ${url} - ${error.response?.status} - ${error.response?.statusText}`);
      // Return a rejected promise with an appropriate message
      throw new Error(`Failed to fetch data from ${url}`);
    }
  }

}
