import type { Location } from '@/domain/entity/Location'
import type { Listing } from '@/domain/entity/Listing'
import { DateUtil } from '@/lib/utils/date'
import type {ListingType} from "@/domain/value/ListingType";

export type CrexiState = {
  code: string;
  name: string;
}

export const CrexiStateFn = {
  toDomain: (crexiState?: CrexiState) => (crexiState && crexiState.code) || ''
}

export type CrexiLocation = {
  address: string;
  city: string;
  county: string;
  state: CrexiState;
  zip: string;
  latitude: number;
  longitude: number;
  fullAddress: string;
}

export const CrexiLocationFn = {
  toDomain: (crexiLocation: CrexiLocation): Location => {
    return {
      ...crexiLocation,
      zipCode: crexiLocation.zip,
      address1: crexiLocation.address,
      state: CrexiStateFn.toDomain(crexiLocation.state)
    }
  }
}

export type CrexiListing = {
  id: number;
  name: string;
  description: string;
  thumbnailUrl: string;
  urlSlug: string;
  brokerTeamLogoUrl: string;
  brokerageName: string;
  activatedOn: string;
  isInOpportunityZone: boolean;
  hasVideo: boolean;
  numberOfGalleryItems: number;
  showCountdownAsDate: boolean;
  askingPrice: number;
  userIsAssetOwner: boolean;
  updatedOn: string;
  numberOfImages: number;
  types: string[];
  hasOm: boolean;
  hasFlyer: boolean;
  status: string;
  hasVirtualTour: boolean;
  locations: CrexiLocation[];
  isNew: boolean;

  createdAt: string,
  updatedAt: string;
  inPurgatory: boolean;
  importName?: string;
  importType?: ListingType;
  deleted: boolean;
  imported: boolean;
}

export const CrexiListingFn = {
  toDomain: (crexiListing: CrexiListing): Listing => {
    const [mainLocation, ...additionalLocations] = crexiListing.locations.length ? crexiListing.locations : []
    const externalUrl = `https://www.crexi.com/properties/${crexiListing.id}/${crexiListing.urlSlug}`
    return {
      externalUrl: externalUrl,
      askingPrice: crexiListing.askingPrice,
      geo: { latitude: mainLocation.latitude, longitude: mainLocation.longitude },
      image: { thumbnailUrl: crexiListing.thumbnailUrl },
      additionalLocations: (additionalLocations || []).map(l => CrexiLocationFn.toDomain(l)),
      name: crexiListing.name,
      primaryLocation: CrexiLocationFn.toDomain(mainLocation),
      id: crexiListing.id.toString(),
      brokerDateListed: DateUtil.parseString(crexiListing.activatedOn),
      brokerName: crexiListing.brokerageName,
      changes: (crexiListing as any).changes,
      description: crexiListing.description,
      createdAt: DateUtil.parseString(crexiListing.createdAt),
      updatedAt: DateUtil.parseString(crexiListing.updatedAt),
      externalId: crexiListing.id.toString(),
      inPurgatory: crexiListing.inPurgatory,

      importName: crexiListing.importName || crexiListing.name,
      importType: crexiListing.importType || undefined,
      imported: false,
      deleted: false
    }
  }

}
