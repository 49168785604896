export const cfg = (config:Config):any => {
  const cfg = (window as any)
  if(cfg.dataAnalystBootstrapConfig[config] !== undefined){
    return cfg.dataAnalystBootstrapConfig[config]
  } else {
    console.error(`config value for '${config}' is missing`)
  }
}
export enum Config {
  ENV = 'env',
  AUTH_SERVER_URL = 'authServerUrl',
  WEB_SOCKET_PROTOCOL = 'webSocketProtocol',
  GOOGLE_MAPS_API_KEY = 'googleMapsApiKey',
  FEATURES = 'features',
  DOMAIN = 'domain',
  HTTP_TIMEOUT_MILLIS = 'httpTimeoutMillis',
  PAGE_SIZE = 'gridPageSize',
  SENTRY_ENABLED = 'sentryEnabled'
}

