import { AuthenticationError, AuthErrorType, type AuthProvider } from '@/domain/provider/AuthProvider'
import Keycloak, { type KeycloakOnLoad } from 'keycloak-js'
import { cfg, Config } from '@/lib/utils/config'

const config: {
  onLoad: KeycloakOnLoad
  refreshIfWithinSeconds: number
  clientId: string
} = {
  onLoad: 'login-required',
  refreshIfWithinSeconds: 5,
  clientId: 'public'
}
let keycloak:Keycloak
if(!(Keycloak as any).didInitialize){
  keycloak = new Keycloak({
    clientId: 'public',
    realm: 'slingshot',
    url: cfg(Config.AUTH_SERVER_URL)
  })
}
export const KeycloakAuthProvider: AuthProvider = {
  authenticate: async (): Promise<boolean | AuthenticationError> => {
    return keycloak.init({ onLoad: config.onLoad })
      .then(authenticated => {
        if (authenticated) {
          return authenticated
        } else {
          throw new AuthenticationError('Authentication failed', AuthErrorType.NOT_AUTHENTICATED)
        }
      })
      .catch(error => {
        throw new AuthenticationError(`Authentication failed ${error}`, AuthErrorType.UNKNOWN)
      })
  },
  logout: (): Promise<void> => {
    return keycloak.logout()
  },
  isAuthenticated: () => !!keycloak.token,
  token: (): string => {
    return keycloak.token ? keycloak.token : 'NO_TOKEN'
  },
  tokenDetails: (): { [k: string]: any } => {
    return keycloak.tokenParsed as { [k: string]: any }
  },
  userRoles: (): string[] | undefined => {
    return keycloak.resourceAccess ? [config.clientId] : undefined
  },
  updateToken: async (): Promise<boolean | void> => {
    return keycloak.updateToken(config.refreshIfWithinSeconds).catch(keycloak.login)
  },
  getAuthorizationHeaderValue: async (): Promise<string> => {
    // Ensure the token is refreshed if necessary
    await KeycloakAuthProvider.updateToken()
    const token = KeycloakAuthProvider.token()
    return `Bearer ${token}`
  }
}
