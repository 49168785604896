<script setup lang="ts">
import {RouterView} from 'vue-router'
import { clearGridLocalStorage } from '@/lib/utils/cache'
import { clearAllTables } from '@/system/db'
import { windowFn } from '@/lib/utils/window'
// if version changed... wipe db and caches
const previousAppVersion = localStorage.getItem('applicationVersion')
const currentAppVersion = (window as any).applicationVersion
if(previousAppVersion !== currentAppVersion){
  const clearAll = async () => {
    console.info(`New Application Version Detected: prev=${previousAppVersion} new=${currentAppVersion}`)
    // clear cache
    clearGridLocalStorage()
    // clear tables
    await clearAllTables()
    // update new version
    localStorage.setItem('applicationVersion', currentAppVersion)
    // reload window
    windowFn.hardRefresh()
  }
  clearAll()
}
</script>

<template>
  <RouterView />
</template>
