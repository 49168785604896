export enum ListingType {
  STNL="STNL",
  STNL_GROCERY="STNL_GROCERY",
  STRIP_CENTER="STRIP_CENTER",
  SHOPPING_CENTER="SHOPPING_CENTER",
  GROCERY_ANCHORED="GROCERY_ANCHORED",
  VACANT="VACANT",
  UNKNOWN = "UNKNOWN"
}

export const ListingTypeFn = {
  toSelectOptions: () => {
    return Object.keys(ListingType).reverse().map(t => {
      if(t === ListingType.UNKNOWN){
        return {name:'ALL', value: t}
      } else {
        return {name: t.replace('_', ' '), value: t}
      }
    }).sort(t => t.value === ListingType.UNKNOWN ? -1 : 1)
  }
}
