import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '/listings/listings'
    },
    {
      path: '/import',
      component: () => import('@/ui/layouts/MainLayout.vue'),
      children: [
        {
          path: '',
          components: {
            main: () => import('@/ui/views/ListingsImportView.vue')
          },
          meta: {
            id: 'import',
            parentName: 'import',
            pageName: 'Import'
          }
        },
      ],
    },
    {
      path: '/listings',
      component: () => import('@/ui/layouts/MainLayout.vue'),
      children: [
        {
          path: 'import',
          components: {
            main: () => import('@/ui/views/ListingsImportView.vue')
          },
          meta: {
            id: 'import',
            parentName: 'listings',
            pageName: 'Listings › Import'
          }
        },
        {
          path: 'listings',
          components: {
            main: () => import('@/ui/views/ListingsView.vue')
          },
          meta: {
            id: 'listings',
            parentName: 'listings',
            pageName: 'Listings › Imported Listings'
          }
        },
        {
          path: 'purgatory',
          components: {
            main: () => import('@/ui/views/ListingsPurgatoryView.vue')
          },
          meta: {
            id: 'purgatory',
            parentName: 'listings',
            pageName: 'Listings › Purgatory'
          }
        },
        {
          path: 'sold',
          components: {
            main: () => import('@/ui/views/ListingsSoldView.vue')
          },
          meta: {
            id: 'sold',
            parentName: 'listings',
            pageName: 'Listings › Sold'
          }
        },
        {
          path: 'no-sold',
          components: {
            main: () => import('@/ui/views/ListingsNoSoldView.vue')
          },
          meta: {
            id: 'no-sold',
            parentName: 'listings',
            pageName: 'Listings › No Sold'
          }
        },
        {
          path: 'duplicates',
          components: {
            main: () => import('@/ui/views/ListingDuplicatesView.vue')
          },
          meta: {
            id: 'duplicates',
            parentName: 'listings',
            pageName: 'Listings › Duplicates'
          }
        },
      ],
    },
    {
      path: '/properties',
      component: () => import('@/ui/layouts/MainLayout.vue'),
      children: [
        {
          path: 'properties',
          components: {
            main: () => import('@/ui/views/PropertiesView.vue')
          },
          meta: {
            id: 'properties',
            parentName: 'properties',
            pageName: 'Properties › Properties'
          }
        },
        {
          path: 'property',
          components: {
            main: () => import('@/ui/views/PropertyView.vue')
          },
          meta: {
            id: 'property',
            parentName: 'properties',
            pageName: 'Properties › Property'
          }
        },
        {
          path: 'addresses',
          components: {
            main: () => import('@/ui/views/AddressesView.vue')
          },
          meta: {
            id: 'addresses',
            parentName: 'properties',
            pageName: 'Properties › Addresses'
          }
        },
        {
          path: 'chains',
          components: {
            main: () => import('@/ui/views/ChainsView.vue')
          },
          meta: {
            id: 'chains',
            parentName: 'properties',
            pageName: 'Properties › Property'
          }
        },
      ]
    },
    {
      path: '/crm',
      component: () => import('@/ui/layouts/MainLayout.vue'),
      children: [
        {
          path: 'contacts',
          components: {
            main: () => import('@/ui/views/ContactsView.vue')
          },
          meta: {
            id: 'contacts',
            parentName: 'crm',
            pageName: 'CRM › Contacts'
          }
        },
        {
          path: 'companies',
          components: {
            main: () => import('@/ui/views/CompaniesView.vue')
          },
          meta: {
            id: 'companies',
            parentName: 'crm',
            pageName: 'CRM › Companies'
          }
        }

      ]
    },
    {
      path: '/system',
      component: () => import('@/ui/layouts/MainLayout.vue'),
      children: [
        {
          path: 'settings',
          components: {
            main: () => import('@/ui/views/system/SettingsView.vue')
          },
          meta: {
            id: 'settings',
            parentName: 'system',
            pageName: 'System › Settings'
          }
        },
        {
          path: 'jobs',
          components: {
            main: () => import('@/ui/views/system/JobsView.vue')
          },
          meta: {
            id: 'jobs',
            parentName: 'system',
            pageName: 'System › Jobs'
          }
        },
        {
          path: 'users',
          components: {
            main: () => import('@/ui/views/system/UsersView.vue')
          },
          meta: {
            id: 'users',
            parentName: 'system',
            pageName: 'System › Users'
          }
        }
      ]
    }
  ]
})

export default router
